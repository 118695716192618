import * as React from "react"
import { useDocument } from "swr-firestore-v9"
import { Box, Stack, Image, VStack, HStack, Text, Divider, Center, useStyleConfig, Heading } from "@chakra-ui/react"
import { useConstants } from "../../contexts/ConstantsProvider"
// import { useUser } from "../../contexts/UserProvider"
import { useParams } from "react-router-dom"
import { useUI } from "../../contexts/UIProvider"
// import { HeadingGroup } from "../../members/userProfile/HeadingGroup"
import Card from "../../components/base/Card"
import format from "date-fns/format"
import { MdCheck } from "react-icons/md"
import { BeatLoader } from "react-spinners"
import { useTermsAndConditions } from "../../hooks/use-terms-and-conditions"
import ReactMarkdown from "react-markdown"
import rehypeRaw from "rehype-raw"
import { TermsContainer } from "../../components/terms/terms-container"
import PageLoading from "../../components/loading/PageLoading"

export default function AdminSignupConfirmationPage() {
    const constants = useConstants()
    // const { user } = useUser()
    const { id } = useParams()
    let { data: user, loading } = useDocument(`users/${id}`, { parseDates: ["createdAt", "updatedAt", "birthday"]}, )

    const termsAndConditions = useTermsAndConditions()
    const { variant } = useUI()
    const styles = useStyleConfig("AuthLoading", { variant })
    const loaderStyles = useStyleConfig("BeatLoader", { variant })
    const headingStyles = useStyleConfig("HeadingGroup", { variant })


    const strings = constants.strings.members.memberConfirmation
    const formStrings = constants.strings.members.signupForm
    constants.strings.setLanguage("ko")
    // console.log(strings)

    // const birthday = user?.birthday.toDate ? user.birthday.toDate() : (user?.birthday || " ")

    if (loading) return <PageLoading />

    return <Box maxW="xl" mx="auto">
        <Stack spacing="12">
            <Stack as="section" spacing="4">
                <Card shadow={0} variant={`${variant}-bg`}>
                    <Stack spacing="1" __css={headingStyles} px={1}>
                        <Heading size="md" fontWeight="semibold">
                            {strings.title}
                        </Heading>
                        <Text fontSize={"sm"} pb={2}>{strings.description}</Text>
                        <Text fontSize={"sm"} fontWeight={"semibold"}>Confirmation No.</Text>
                        <Text fontSize={"sm"}>{user ? user.id : " "}</Text>
                    </Stack>
                </Card>
                {
                    user ? <VStack w={"full"} px={{ base: 6, md: 4 }} color={"gray.600"}>
                            <HStack justify={"space-between"} w={"full"} maxW={"sm"}>
                                <Text as={"span"} fontWeight={"semibold"}>{formStrings.nameLabel}</Text>
                                <Text as={"span"}>{user.name}</Text>
                            </HStack>
                            <HStack justify={"space-between"} w={"full"} maxW={"sm"}>
                                <Text as={"span"} fontWeight={"semibold"}>{formStrings.emailLabel}</Text>
                                <Text as={"span"}>{user.email}</Text>
                            </HStack>
                            <HStack justify={"space-between"} w={"full"} maxW={"sm"}>
                                <Text as={"span"} fontWeight={"semibold"}>{formStrings.birthdayLabel}</Text>
                                <Text as={"span"}>{user.birthday ? format(user.birthday, "MM.dd.yyyy") : " "}</Text>
                            </HStack>
                            <Divider borderColor={"gray.300"} pt={2}/>
                            <HStack justify={"space-between"} w={"full"} maxW={"sm"}>

                                <Text as={"span"}>{strings.agreementToTermsAndConditions}</Text>
                                <Text as={"span"} fontWeight={"semibold"}><MdCheck/></Text>
                            </HStack>
                            <HStack justify={"space-between"} w={"full"} maxW={"sm"}>

                                <Text as={"span"}>{strings.agreementToPrivacyPolicy}</Text>
                                <Text as={"span"} fontWeight={"semibold"}><MdCheck/></Text>
                            </HStack>
                            <HStack justify={"space-between"} w={"full"} maxW={"sm"}>
                                <Text as={"span"}>{strings.agreementToRefundPolicy}</Text>
                                <Text as={"span"} fontWeight={"semibold"}><MdCheck/></Text>
                            </HStack>
                            <Divider borderColor={"gray.300"} pt={2}/>

                            {user.signature &&
                                <Image alt={"signature"} src={user.signature} w={"full"} maxW={"sm"} h={"auto"} bg={"white"}
                                       rounded={"md"}/>}
                            <Box w={"full"} maxW={"sm"}>
                                <Text fontSize={"sm"} pl={1}>
                                    {strings.agreementSignedAt}{format(user.createdAt, "yyyy년 M월 dd일 hh:mm:ss")}
                                </Text>
                            </Box>
                            <Divider borderColor={"gray.300"} pt={2}/>
                            <VStack>
                                <Text w={"full"} fontWeight={"semibold"} fontSize={"md"}>{ "Terms & Conditions" }</Text>
                                <TermsContainer px={0}>{termsAndConditions.termsAndConditions && <ReactMarkdown
                                rehypePlugins={[ rehypeRaw ]}
                                children={termsAndConditions.termsAndConditions}
                            />}</TermsContainer></VStack>
                            <VStack>
                                <Text w={"full"} fontWeight={"semibold"} fontSize={"md"}>{ formStrings.personalInfoCollectionLabel }</Text>
                                <TermsContainer px={0}>{termsAndConditions.useOfPersonalInformation && <ReactMarkdown
                                    rehypePlugins={[ rehypeRaw ]}
                                    children={termsAndConditions.useOfPersonalInformation}
                                />}</TermsContainer></VStack>
                            <VStack>
                                <Text w={"full"} fontWeight={"semibold"} fontSize={"md"}>{ formStrings.refundPolicyLabel }</Text>
                                <TermsContainer px={0}>{termsAndConditions.refundPolicy && <ReactMarkdown
                                    rehypePlugins={[ rehypeRaw ]}
                                    children={termsAndConditions.refundPolicy}
                                />}</TermsContainer></VStack>
                        </VStack> :
                        <Center h={"60vh"} __css={styles}>
                            <Center>
                                <VStack>
                                    <BeatLoader
                                        {...loaderStyles}
                                        // color={"var(--chakra-colors-brand-500)"}
                                    />
                                </VStack>
                            </Center>
                        </Center>
                }

            </Stack>
        </Stack></Box>
}