import * as React from "react"
import {
    Box,
    Flex,
    useDisclosure,
    useColorModeValue as mode,
    Spacer,
    HStack,
    VStack,
    IconButton,
    Stack,
    Button,
    useMultiStyleConfig
} from "@chakra-ui/react"
import { useNavigate, useLocation, matchPath } from "react-router-dom"
import NavbarTabs from "./NavbarTabs"
import NavTabLink from "./NavTabLink"
import MobileTabs from "./MobileTabs"
import MembersMobileNavContent from "./MembersMobileNavContent"

// import WhiteLogo from "./NavLogo"
import { TextWithTinyLogoDark } from "../../components/logo/logo"
import UserProfile from "./UserProfile"

// import { useMenu } from "../../contexts/MenuProvider"
import { useUI } from "../../contexts/UIProvider"
import { HamburgerIcon } from "@chakra-ui/icons"
import { useConstants } from "../../contexts/ConstantsProvider"
import { useUser } from "../../contexts/UserProvider"


export const MembersNavbar = ({ auth }) => {

    const ui = useUI()
    const { user } = useUser()
    const styles = useMultiStyleConfig("MembersNavbar", { ...ui })

    // const { getMenuFor } = useMenu()
    // const version = auth.currentUser ? "members" : "site"
    // const tabs = getMenuFor(version).filter(tab => tab.path)
    const tabs = React.useMemo(() => {
        if (!user) return []
        return [
            {
                name: "accountSettings",
                title: "Settings",
                section: "Collections",
                path: `/users/${user.id}/edit`
            },
            {
                name: "MemberConfirmation",
                title: "Member Confirmation",
                section: "Collections",
                path: `/users/${user.id}/view`
            }
        ]
    }, [user])
    // hack for a quick fix

    // if (user) {
    //     // console.log("user", user)
    //     tabs.push()
    //     tabs.push()
    // }


    const constants = useConstants()
    const navigate = useNavigate()
    const location = useLocation()
    const { pathname } = location

    // console.log("MembersNavbar", tabs, pathname)

    //
    const mobileNav = useDisclosure()
    const [ logoLoaded, setLogoLoaded ] = React.useState(0)
    const [ mobileLogoLoaded, setMobileLogoLoaded ] = React.useState(0)


    // console.log("navbar", menu.menu)
    // let tabs = menu.menu.sections.reduce((o, section) => {
    //     menu.menu.groups[section].map(col => o.push(col))
    //     return o
    // }, [])
    // console.log(pathname, tabs.findIndex(tab => pathname.startsWith(tab.path)))

    const [ tabIndex, setTabIndex ] = React.useState(tabs.findIndex(tab => {
            if (!tab?.path) return false

            return matchPath({ path: tab.path }, pathname)
        }
        // pathname.startsWith(tab.path)
    ))

    React.useEffect(() => {
        setTabIndex(tabs.findIndex(tab => {
                if (!tab?.path) return false

                return matchPath({ path: tab.path }, pathname)
            }
            // pathname.startsWith(tab.path)
        ))
    }, [user, pathname, tabs])

    const handleTabsChange = (index) => {
        navigate(tabs[index].path, { replace: true })
        mobileNav.onClose()
        setTabIndex(index)
    }

    return (
        <Box className={"members-navbar"}
             __css={styles.navbar}>

                <Flex
                    py={4}
                    px={{
                        base: 4,
                        md: 6,
                        lg: 8,
                    }}
                    // bg={mode(...bg)}
                    boxShadow="none"
                    borderBottomWidth={mode('0', '1px')}
                >
                    {/*<WhiteLogo onLoad={() => setLogoLoaded(prev => prev + 1)}/>*/}
                    <TextWithTinyLogoDark
                        imgProps={{
                            onLoad: () => setLogoLoaded(prev => prev + 1)
                        }}
                    />
                    <HStack
                        display={{
                            base: 'none',
                            md: 'flex',
                        }}
                        marginStart={4}
                    >
                        <NavbarTabs
                            logoLoaded={logoLoaded}
                            tabIndex={tabIndex}
                            onTabChange={handleTabsChange}>
                            {
                                tabs.map(tab => <NavTabLink key={tab.name}>{tab.title}</NavTabLink>)
                            }
                        </NavbarTabs>
                    </HStack>
                    <Spacer/>
                    <HStack
                        display={{
                            base: 'none',
                            md: 'flex',
                        }}
                        spacing={3}
                    >
                        {auth.currentUser ? <UserProfile/> : <div/>}
                    </HStack>
                    <VStack justify={"space-around"}>
                        <IconButton
                            display={{
                                base: 'flex',
                                md: 'none',
                            }}
                            size="sm"
                            aria-label="Open menu"
                            fontSize="28px"
                            variant="ghost"
                            onClick={mobileNav.onOpen}
                            icon={<HamburgerIcon/>}
                            __css={styles.hamburger}
                        />
                    </VStack>

                    <MembersMobileNavContent isOpen={mobileNav.isOpen} onClose={mobileNav.onClose}>
                        <VStack h={"full"} justifyContent={"space-between"}>
                        <Stack spacing={5} className={"tabs-stack"} w={"full"}>
                            <Box mb={2}>
                                <TextWithTinyLogoDark
                                    imgProps={{
                                        onLoad: () => setMobileLogoLoaded(prev => prev + 1)
                                    }}/>
                                {/*<WhiteLogo onLoad={() => setMobileLogoLoaded(prev => prev + 1)}/>*/}
                            </Box>

                            <MobileTabs
                                logoLoaded={mobileLogoLoaded}
                                tabIndex={tabIndex}
                                onTabChange={handleTabsChange}
                            >
                                {
                                    tabs.map(tab => <NavTabLink key={tab.name}>{tab.title}</NavTabLink>)
                                }
                            </MobileTabs>
                        </Stack>
                        <Stack spacing={5} className={"profile-stack"} w={"full"}>

                            {auth.currentUser ?

                                <Box
                                    px={4}
                                    rounded={"lg"}
                                    justifyContent={"flex-start"}
                                    onClick={() => {
                                        mobileNav.onClose()
                                        navigate(`/users/${user.id}/edit`)
                                    }}
                                    w={"full"}
                                    bg={"white"}>
                                    <Box py={3}>
                                        <UserProfile/>
                                    </Box>

                                </Box>
                                : <div/>
                            }
                            {
                                auth.currentUser ?
                                    <Button onClick={() => {
                                        mobileNav.onClose()
                                        auth.signout(() => navigate(constants.auth.loginRoute))
                                    }}
                                            w={"full"}
                                    >{constants.strings.signout}</Button>
                                    :
                                    <Button
                                        size={"lg"}
                                        onClick={() => {
                                            mobileNav.onClose()
                                            navigate(constants.auth.signupRoute)
                                        }}
                                        w={"full"}
                                    >{constants.strings.site.navbar.signupForFree}</Button>

                            }

                        </Stack>
                        </VStack>

                    </MembersMobileNavContent>
                </Flex>

        </Box>
    )
}

export default MembersNavbar
